import { Link } from 'react-router-dom';
import { ManOfferIcon } from '../ui/manOfferIcon';

export function SectionMan() {
  return (
    <>
      <section id="man">
        <div className="desc">
          <h2>Mężczyzna</h2>
          <ManOfferIcon />
          <Link className='more' to={'/mezczyzna'}>Zobacz więcej</Link>
        </div>
        <div className="image">
          <img src='/img/mezczyzna.jpg' alt="Mężczyzna oferta stylizacji" width={945} height={1080} loading='lazy' />
        </div>
      </section>
    </>
  )
}