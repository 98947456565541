import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import '../scss/blog.scss';
import { Header } from "./ui/header";
import { Footer } from "./ui/footer";


export function Blog() {
  const [article, setBlog] = useState([]);
  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await fetch(`/blog/data/blog.json`, {
          headers: {
            'Cache-Control': 'no-cache', // sugeruje, by unikać użycia cache
            'Pragma': 'no-cache' // dla zgodności z HTTP/1.0
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const loadedArticles = await response.json();
        setBlog(loadedArticles.sort((a, b) => b.id - a.id));
      } catch (error) {
        console.log("Nie udało się załadować projektów", error);
      }
    };
  
    fetchBlog();
  }, []);

  useEffect(() => {
    // Dodawanie klasy 'blog' do <body> przy montowaniu komponentu
    document.body.classList.add('blog');

    // Funkcja czyszcząca, która będzie wywoływana przy odmontowywaniu komponentu
    return () => {
      // Usuwanie klasy 'blog' z <body>
      document.body.classList.remove('blog');
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>Blog Adrianny Malewskiej</title>
        <meta name="description" content={'Blog po jasnej stronie mody, zobacz co w modzie piszczy...'} />
      </Helmet>
      <Header />
      <article>
        <h1>Blog po jasnej stronie mody – Adrianna Malewska</h1>
        <div id="articles">
          {article.map(article => (
            <div key={article.id} className="item">
              <img src={article.thumbnail} alt={article.title} width={800} height={450} loading='lazy' />
              <Link to={`/blog/${article.slug}`}>{article.title}</Link>
              <p>{article.shortDesc}</p>
            </div>
          ))}
        </div>
      </article>
      <Footer />
    </>
  )
}