import { useState, useRef, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";

export function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const mainNavRef = useRef(null);
  const logoRef = useRef(null);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
    const mainNav = mainNavRef.current;
    const logoMenu = logoRef.current;
    if (mainNav && logoMenu) {
      mainNav.classList.toggle('open');
      logoMenu.classList.toggle('menuOpen');
    }
    document.body.classList.toggle('noScroll');
  };

  const [articles, setArticles] = useState([]);
  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await fetch(`/blog/data/blog.json`, {
          headers: {
            'Cache-Control': 'no-cache', // sugeruje, by unikać użycia cache
            'Pragma': 'no-cache' // dla zgodności z HTTP/1.0
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const loadedArticles = await response.json();
        setArticles(loadedArticles.sort((a, b) => b.id - a.id).slice(0, 3));
      } catch (error) {
        console.log("Nie udało się załadować artykułów", error);
      }
    };
  
    fetchBlog();
  }, []);

  return (
    <>
      <nav id="mainNav" ref={mainNavRef}>
        <div id="nav">
          <ul>
            <li><NavLink onClick={toggleNav} to={'/'} activeclassname="active">Witaj</NavLink></li>
            <li><NavLink onClick={toggleNav} to={'/o-mnie'} activeclassname="active">O mnie</NavLink></li>
            <li><NavLink onClick={toggleNav} to={'/kobieta'} activeclassname="active">Kobieta</NavLink></li>
            <li><NavLink onClick={toggleNav} to={'/mezczyzna'} activeclassname="active">Mężczyzna</NavLink></li>
            <li><NavLink onClick={toggleNav} to={'/blog'} activeclassname="active">Blog</NavLink></li>
            <li><NavLink onClick={toggleNav} to={'/kontakt'} activeclassname="active">Kontakt</NavLink></li>
          </ul>
        </div>

        <div id="blogNews">
          <h3>Najnowsze na blogu</h3>
          <ul>
            {articles.map(article => (
              <li key={article.id}><Link onClick={toggleNav} to={`/blog/${article.slug}`}>{article.title}</Link></li>
            ))}
          </ul>
        </div>
      </nav>
      <header>
        <Link id='logo' ref={logoRef} to='/'>
          <img src='/img/logo.svg' alt="Logo Malewska" width="336.5" height="140" />
        </Link>
        <button onClick={toggleNav} id="navBarToggle" className={isNavOpen ? 'close' : ''} aria-controls="mainNav" aria-expanded={isNavOpen}>
          <span id="toggle">
            <span className="line"></span>
            <span className="line"></span>
          </span>
        </button>
      </header>
    </>
  );
}
