import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
// import image from '../../img/blog.jpg';

export function SectionBlog() {
  const [article, setBlog] = useState([]);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await fetch(`/blog/data/blog.json`, {
          headers: {
            'Cache-Control': 'no-cache', // sugeruje, by unikać użycia cache
            'Pragma': 'no-cache' // dla zgodności z HTTP/1.0
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const loadedProjects = await response.json();
        setBlog(loadedProjects.sort((a, b) => b.id - a.id).slice(0, 3));
      } catch (error) {
        console.log("Nie udało się załadować projektów", error);
      }
    };
  
    fetchBlog();
  }, []);

  return (
    <>
      <section id="blogSec">
        <h2>Blog po jasnej stronie mody</h2>
        <div id="articles">
          {article.map(project => (
            <div key={project.id} className="item">
              <img src={project.thumbnail} alt={project.title} width={800} height={450} loading='lazy' />
              <Link to={`/blog/${project.slug}`}>{project.title}</Link>
              <p>{project.shortDesc}</p>
            </div>
          ))}
        </div>
      <Link className="more" to={'/blog'}>Zobacz bloga</Link>
      </section>
    </>
  )
}