import { Link } from "react-router-dom";

export function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer>
        <h2>Porozmawiajmy</h2>
        <div id="contactBottom">
            <div id="phone">
              <a href="tel:796200365">Tel. 796 200 365</a>
              <p>Zadzwoń</p>
            </div>
            <div id="mail">
              <a href="mailto:kontakt@malewska.com">kontakt@malewska.com</a>
              <p>Lub napisz</p>
            </div>
        </div>
          <p id="copyright">® {currentYear} malewska.com | <Link to={'#'}>Polityka prywatności</Link></p>
          <a id="createBy" href="https://wandachowicz.com" target="_blank" rel="noreferrer" title="Tworzę piękne rzeczy dla wyjątkowych ludzi.">
            <img src='/img/wandachowicz.svg' alt="Tworzę piękne rzeczy dla wyjątkowych ludzi." width={55.1} height={50} />
          </a>
      </footer>
    </>
  )
}