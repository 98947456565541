import { Link } from 'react-router-dom';
export function AboutMe() {
  return (
    <>
      <section id="aboutMe">
        <div className="desc">
          <h2>Witaj</h2>
          <p>
          Nazywam się Adrianna, od wielu lat zajmuje się kreowaniem wizerunku i jest to moja życiowa pasja :)
          Dzięki dziesiątkom przeprowadzonych metamorfoz żaden rodzaj sylwetki lub typ kolorystyczny nie jest mi obcy.
          W ciągu ostatnich kilku lat miałam przyjemność współpracować przy eventach, pokazach mody oraz sesjach zdjęciowych.
          Na co dzień uwielbiam szyć, malować, zajmować się moimi zwierzętami oraz gotować smakołyki dla bliskich.
          Jeśli czujesz, że to jest moment by zmienić swój wizerunek, poczuć się lepiej lub odkryć się na nowo <a href="mailto:kontakt@malewska.com">napisz</a> lub <a href="tel:796200365">zadzwoń</a> do mnie. Sprawię, żebyś zawsze wyglądała wspaniale.</p>

          <p className='greetings'>
            Pozdrawiam i życzę cudownego dnia,<br />
            Adrianna.
          </p>
          <Link className='more' to={'/o-mnie'}>Poznaj mnie</Link>
        </div>
        <div className="image">
          <img src='/img/Ada.jpg' alt="Adrianna Malewska" width={960} height={1440} loading='lazy' />
        </div>
      </section>
    </>
  )
}