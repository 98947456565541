import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import '../scss/contact.scss';
import { Header } from "./ui/header";
import { Footer } from "./ui/footer";


export function Contact() {
  useEffect(() => {
    // Dodawanie klasy 'contact' do <body> przy montowaniu komponentu
    document.body.classList.add('contact');

    // Funkcja czyszcząca, która będzie wywoływana przy odmontowywaniu komponentu
    return () => {
      // Usuwanie klasy 'contact' z <body>
      document.body.classList.remove('contact');
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>Kontakt</title>
        <meta name="description" content={'Zadzwoń lub napisz do mnie - Adrianna Malewska'} />
      </Helmet>
      <Header />
      <Footer />
    </>
  )
}