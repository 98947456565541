import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import '../scss/articles.scss';
import { Header } from "./ui/header";
import { WomanOfferIcon } from "./ui/womanOfferIcon";
import { Footer } from "./ui/footer";


export function Man() {
  useEffect(() => {
    // Dodawanie klasy 'man' do <body> przy montowaniu komponentu
    document.body.classList.add('man');

    // Funkcja czyszcząca, która będzie wywoływana przy odmontowywaniu komponentu
    return () => {
      // Usuwanie klasy 'man' z <body>
      document.body.classList.remove('man');
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>Zmiana stylu dla mężczyzn</title>
        <meta name="description" content={'Odśwież swój wizerunek – poznaj ofertę zmiany stylu dla mężczyzn'} />
      </Helmet>
      <Header />
      <article>
        <div className="flex">
          <div id="content">
            <h1>Odśwież swój wizerunek – zmiana stylu dla mężczyzn</h1>
            <h2>Przekształć swój styl i zbuduj pewność siebie</h2>
            <p>Czy zastanawiałeś się, jak duże znaczenie dla Twojego wizerunku i samopoczucia ma styl, w jakim się ubierasz? Styl to nie tylko ubrania, ale przede wszystkim sposób na pokazanie swojej osobowości i siły. Adrianna Malewska – profesjonalna stylistka. Pomogę Ci odnaleźć styl, który podkreśli Twoje atuty, zwiększy Twoją pewność siebie i pozwoli skuteczniej budować profesjonalny wizerunek.</p>

            <h2>Dlaczego warto zainwestować w profesjonalne doradztwo stylistyczne?</h2>
            <p>Pierwsze wrażenie jest kluczowe, zarówno w życiu zawodowym, jak i prywatnym. Co masz na sobie, wpływa na to, jak jesteś postrzegany przez innych oraz jak postrzegasz siebie. Pomogę Ci wybrać ubrania, które będą odzwierciedlać Twój charakter, pasować do Twojej sylwetki i sytuacji życiowej, a także wspierać Twoje cele zawodowe i osobiste.</p>

            <h2>Jakie korzyści płyną z profesjonalnej zmiany stylu?</h2>
            <p>Zmiana stylu ubierania z profesjonalną stylistką to inwestycja w Twój rozwój osobisty i zawodowy. Oferuje swoje doświadczenie i wiedzę, aby pomóc Ci:</p>
            <ul>
              <li>Dobierać ubrania, które idealnie pasują do Twojego typu sylwetki,</li>
              <li>wybierać kolory i materiały, które podkreślają Twój naturalny urok,</li>
              <li>tworzyć spójne zestawy ubrań na różne okazje,</li>
              <li>zorganizować i optymalizować Twoją garderobę, aby była praktyczna i stylowa.</li>
            </ul>
            <p>Odkrywanie nowego stylu to przygoda, która pozwali Ci lepiej wyrażać siebie i być postrzeganym jako mężczyzna sukcesu i stylu.</p>

            <h2>Indywidualne podejście – gwarancja sukcesu</h2>
            <p>Każdy mężczyzna jest inny, a dobry styl powinien odzwierciedlać unikalne cechy osobowości. Jako profesjonalna stylistka skupiam się na indywidualnym podejściu, które gwarantuje, że każda propozycja stylizacji będzie idealnie dopasowana do Twoich potrzeb i oczekiwań.</p>

            <h2>Zmień swój styl, zmień swoje życie</h2>
            <p>Zmiana stylu to coś więcej niż nowe ubrania w szafie – to nowa perspektywa na siebie i swoje możliwości. Niezależnie od tego, czy chcesz odświeżyć swój codzienny look, czy potrzebujesz stylizacji na specjalną okazję, zapewnię Ci wsparcie i ekspertyzę, które pozwolą Ci czuć się pewnie i komfortowo w każdej sytuacji.</p>

            <h2>Kontakt</h2>
            <p>Jeśli jesteś gotowy na zmianę, która odmieni nie tylko Twój wizerunek, ale i samopoczucie, skontaktuj się ze mną. <a href="mailto:kontakt@malewska.com">Napisz</a> lub <a href="tel:796200365">zadzwoń</a> do mnie. Pomogę Ci stworzyć Twoją nową, lepszą wersją siebie – nie czekaj, zacznij już dziś!</p>
          </div>
          <WomanOfferIcon />
        </div>
        <div id="ariclePhoto">
          <img src='/img/mezczyzna.jpg' alt="Adrianna Malewska" width={946} height={1081} loading='lazy' />
        </div>
      </article>
      <Footer />
    </>
  )
}