import { Helmet } from "react-helmet-async"
export function Hero() {
  return (
    <>
    <Helmet>
      <link rel="preload" href="/img/heroBg.jpg" as="image" />
    </Helmet>
    <section id="heroSection" style={{
        backgroundImage: 'url("/img/heroBg.jpg")'
      }}>
      <h1>
        Twój<br />styl<br />Twoja<br />Historia<br />
        <span className="subTitle">Pomogę Ci ją opowiedzieć</span>
      </h1>
    </section>
    </>
  )
}