export function ManOfferIcon() {
  return (
    <div className='offer'>
      <ul>
        <li>
          <img src='/img/zakupyZeStylistka.png' alt="Zakupy ze stylistką" width={61} height={98} loading='lazy' />
          <h3>Zakupy ze stylistką</h3>
          <p>Spotkanie zapoznawcze przy kawie oraz omówienie oczekiwań gratis.</p>
          <p className="price">200 zł <span>Każda godzina zakupów.</span></p>
        </li>
        <li>
          <img src='/img/analizaSylwetki.png' alt="Analiza sylwetki" width={100} height={98} loading='lazy' />
          <h3>Analiza sylwetki</h3>
          <p>Określenie typu sylwetki, dobór odpowiedniego kroju ubrań oraz fasonu.</p>
          <p className="price">400 zł</p>
        </li>
        <li>
          <img src='/img/analizaKolorystyczna.png' alt="Analiza kolorystyczna" width={98} height={98} loading='lazy' />
          <h3>Analiza kolorystyczna</h3>
          <p>Analiza kolorystyczna przy użyciu chust, dobór palety barw, określenie typu urody.</p>
          <p className="price">400 zł</p>
        </li>
        <li>
          <img src='/img/przegladSzafy.png' alt="Przegląd szafy" width={90} height={98} loading='lazy' />
          <h3>Przegląd szafy</h3>
          <p>Analiza posiadanych ubrań, tworzenie gotowych zestawów, reorganizacja szafy.</p>
          <p className="price">500 zł</p>
        </li>
        <li>
          <img src='/img/zakupyOnline.png' alt="Zakupy online" width={61} height={98} loading='lazy' />
          <h3>Zakupy online</h3>
          <p>Rozmowa z klientem online (FaceTime, Skype), określenie potrzeb, zakupy online.</p>
          <p className="price">od 200 zł</p>
        </li>
        <li>
          <img src='/img/dzienZeStylistka.png' alt="Dzień ze stylistką wariant 1" width={92} height={98} loading='lazy' />
          <h3>Dzień ze stylistką wariant 1</h3>
          <p>Analiza kolorystyczna + analiza sylwetki + zakupy ze stylistką (bez ograniczeń czasowych).</p>
          <p className="price">1000 zł</p>
        </li>
        <li>
        <img src='/img/dzienZeStylistka.png' alt="Dzień ze stylistką wariant 2" width={92} height={98} loading='lazy' />
          <h3>Dzień ze stylistką wariant 2</h3>
          <p>Analiza kolorystyczna + analiza sylwetki + przegląd szafy + zakupy ze stylistką (bez ograniczeń czasowych).</p>
          <p className="price">1200 zł</p>
        </li>
        <li>
          <img src='/img/bonPodarunkowy.png' alt="Bon podarunkowy" width={148} height={98} loading='lazy' />
          <h3>Bon podarunkowy</h3>
          <p>Każda z usług według cennika.</p>
        </li>
      </ul>
    </div>
  )
}