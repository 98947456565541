import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import '../scss/articles.scss';
import { Header } from "./ui/header";
import { WomanOfferIcon } from "./ui/womanOfferIcon";
import { Footer } from "./ui/footer";

export function About() {
  useEffect(() => {
    // Dodawanie klasy 'about' do <body> przy montowaniu komponentu
    document.body.classList.add('about');

    // Funkcja czyszcząca, która będzie wywoływana przy odmontowywaniu komponentu
    return () => {
      // Usuwanie klasy 'about' z <body>
      document.body.classList.remove('about');
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>O mnie — Adrianna Malewska</title>
        <meta name="description" content={'Twoja stylistka – wspolnie stworzymy Twój nowy, wyjątkowy wizerunek.'} />
      </Helmet>
      <Header />
      <article>
        <div className="flex">
          <div id="content">
            <h1>Adrianna Malewska - z pasją do stylu</h1>
            <p>Nazywam się Adrianna i od wielu lat zajmuję się kreowaniem wizerunku, co jest moją życiową pasją. Przez lata pracy zdobyłam doświadczenie, które pozwala mi na skuteczną pracę z różnymi typami sylwetek i paletami kolorystycznymi.</p>
            <h2>Doświadczenie i specjalizacja</h2>
            <p>Jako doświadczona stylistka, miałam przyjemność współpracować przy różnorodnych projektach, od eventów, przez pokazy mody, aż po sesje zdjęciowe. Każde z tych doświadczeń pozwoliło mi jeszcze lepiej zrozumieć potrzeby i oczekiwania klientów.</p>
            <h2>Przeprowadzone metamorfozy</h2>
            <p>Przez lata mojej pracy przeprowadziłam dziesiątki metamorfoz, dzięki czemu żaden rodzaj sylwetki ani typ kolorystyczny nie jest mi obcy. Każda metamorfoza to nowe wyzwanie i możliwość do pokazania pełni moich umiejętności.</p>
            <h2>Pasja poza pracą</h2>
            <p>Moje życie poza pracą jest pełne barw. Uwielbiam spędzać czas aktywnie: od szycia (kreowanie własnych projektów ubrań), poprzez malowanie, opiekę nad zwierzętami. Nie brakuje mi również pasji do gotowania, gdzie mogę eksperymentować ze smakami dla moich bliskich.</p>
            <h2>Gotowa na zmianę?</h2>
            <p>Jeśli czujesz, że nadszedł czas na zmianę Twojego wizerunku, chcesz poczuć się lepiej w swojej skórze lub po prostu odkryć siebie na nowo – zapraszam do kontaktu. Gwarantuję, że razem sprawimy, że zawsze będziesz wyglądać i czuć się wspaniale!</p>
            {/* <h2>Poznaj moją ofertę</h2> */}
            <h2>Każdy moment jest dobry na zmianę</h2>
            <p><a href="mailto:kontakt@malewska.com">Napisz</a> lub <a href="tel:796200365">zadzwoń</a> do mnie. Wspolnie stworzymy Twój nowy, wyjątkowy wizerunek.</p>
          </div>
          <WomanOfferIcon />
        </div>
        {/* <div id="ariclePhoto">
          <img src='/img/Ada.jpg' alt="Adrianna Malewska" width={960} height={1440} loading='lazy' />
        </div> */}
      </article>
    <Footer />
    </>
  )
}