import { Link } from 'react-router-dom';
import { WomanOfferIcon } from '../ui/womanOfferIcon';

export function SectionWoman() {
  return (
    <>
      <section id="woman">
        <div className="image">
          <img src='/img/kobieta.jpg' alt="Kobieta oferta stylizacji" width={2160} height={1440} loading='lazy' />
        </div>
        <div className="desc">
          <h2>Kobieta</h2>
          <WomanOfferIcon />
          <Link className='more' to={'/kobieta'}>Zobacz więcej</Link>
        </div>
      </section>
    </>
  )
}