import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import './App.scss';
import { Routes, Route } from 'react-router-dom';
import { Home } from './components/home';
import { About } from './components/about';
import { Woman } from './components/woman';
import { Man } from './components/man';
import { Blog } from './components/blog';
import { Single } from './components/blog/single';
import { Contact } from './components/contact';

export default function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <Helmet>
        <link rel="preload" href="/fonts/work-sans-v19-latin_latin-ext-100.woff2" as="font" type="font/woff2" crossorigin="anonymous" />
        <link rel="preload" href="/fonts/work-sans-v19-latin_latin-ext-100italic.woff2" as="font" type="font/woff2" crossorigin="anonymous" />
        <link rel="preload" href="/fonts/work-sans-v19-latin_latin-ext-200.woff2" as="font" type="font/woff2" crossorigin="anonymous" />
        <link rel="preload" href="/fonts/work-sans-v19-latin_latin-ext-200italic.woff2" as="font" type="font/woff2" crossorigin="anonymous" />
        <link rel="preload" href="/fonts/work-sans-v19-latin_latin-ext-regular.woff2" as="font" type="font/woff2" crossorigin="anonymous" />
        <link rel="preload" href="/fonts/forum-v18-latin_latin-ext-regular.woff2" as="font" type="font/woff2" crossorigin="anonymous" />
        <style>{`
          @font-face {
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 100;
            font-display: fallback;
            src: url('/fonts/work-sans-v19-latin_latin-ext-100.woff2') format('woff2');
          }
          @font-face {
            font-family: 'Work Sans';
            font-style: italic;
            font-weight: 100;
            font-display: fallback;
            src: url('/fonts/work-sans-v19-latin_latin-ext-100italic.woff2') format('woff2');
          }
          @font-face {
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 200;
            font-display: fallback;
            src: url('/fonts/work-sans-v19-latin_latin-ext-200.woff2') format('woff2');
          }
          @font-face {
            font-family: 'Work Sans';
            font-style: italic;
            font-weight: 200;
            font-display: fallback;
            src: url('/fonts/work-sans-v19-latin_latin-ext-200italic.woff2') format('woff2');
          }
          @font-face {
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 400;
            font-display: fallback;
            src: url('/fonts/work-sans-v19-latin_latin-ext-regular.woff2') format('woff2');
          }
          @font-face {
            font-family: 'Forum';
            font-style: normal;
            font-weight: 400;
            font-display: fallback;
            src: url('/fonts/forum-v18-latin_latin-ext-regular.woff2') format('woff2');
          }
        `}</style>
        <meta property="og:locale" content="pl_PL" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Adrianna Malewska — profesjonalna stylistka" />
        <meta property="og:description" content="Twój styl, Twoja historia — pomogę Ci ją opowiedzieć." />
        <meta property="og:url" content="https://malewska.com/" />
        <meta property="og:site_name" content="Adrianna Malewska — profesjonalna stylistka" />
        <meta property="og:image" content="https://malewska.com/img/malewska.png" />
        <meta property="og:image:width" content="1366" />
        <meta property="og:image:height" content="768" />
        <meta property="og:image:type" content="image/png" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/o-mnie' element={<About />} />
        <Route path='/kobieta' element={<Woman />} />
        <Route path='/mezczyzna' element={<Man />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/blog/:slug' element={<Single />} />
        <Route path='/kontakt' element={<Contact />} />
      </Routes>
    </>
  );
}