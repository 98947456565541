import { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import { Helmet } from 'react-helmet-async';
import '../../scss/articles.scss';
import { useParams, Link } from 'react-router-dom';
import { Header } from "../ui/header";
import { Footer } from "../ui/footer";

export function Single() {
  const [article, setArticle] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    const fetchArticlesDetails = async () => {
      try {
        const response = await fetch(`/blog/data/blog.json`, {
          headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache'
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const loadedArticle = await response.json();
        const articleData = loadedArticle.find(article => article.slug === slug);
        setArticle(articleData);
      } catch (error) {
        console.log("Nie udało się załadować artykułu", error);
      }
    };

    fetchArticlesDetails();
  }, [slug]);

  useEffect(() => {
    document.body.classList.add('single');
    return () => {
      document.body.classList.remove('single');
    };
  }, []);

  if (!article) {
    return (
      <>
        <Helmet>
          <title>Artykułu nie znaleziono</title>
          <meta name="description" content={'Artykułu nie znaleziono'} />
        </Helmet>
        <Header />
        <h2>Niestety nie znalazłem tego artykułu blogowego ;(</h2>
        <Link to={'/'}>Wróć na stronę główną</Link>
      </>
    );
  }

  const cleanHTML = DOMPurify.sanitize(article.content);

  return (
    <>
      <Helmet>
        <title>{article.title}</title>
        <meta name="description" content={article.shortDesc} />
      </Helmet>
      <Header />
      <section id="heroSection">
        <h1>{article.title}</h1>
        <img src={article.coverImage} alt={article.title} width={2560} height={1440} />
      </section>
      <article>
        <div id='content' dangerouslySetInnerHTML={{ __html: cleanHTML }}></div>
      </article>
      <Footer />
    </>
  );
}
