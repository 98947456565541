import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import '../scss/home.scss';
import { Header } from "./ui/header";
import { Footer } from "./ui/footer";
import { Hero } from "./home/hero";
import { AboutMe } from "./home/about";
import { SectionWoman } from "./home/sectionWoman";
import { SectionMan } from "./home/sectionMan";
import { SectionBlog } from "./home/sectionBlog";


export function Home() {
  useEffect(() => {
    // Dodawanie klasy 'home' do <body> przy montowaniu komponentu
    document.body.classList.add('home');

    // Funkcja czyszcząca, która będzie wywoływana przy odmontowywaniu komponentu
    return () => {
      // Usuwanie klasy 'home' z <body>
      document.body.classList.remove('home');
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>Adrianna Malewska</title>
        <meta name="description" content={'Twoja stylistka'} />
      </Helmet>
      <Header />
      <Hero />
      <AboutMe />
      <SectionWoman />
      <SectionMan />
      <SectionBlog />
      <Footer />
    </>
  )
}