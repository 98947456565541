import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import '../scss/articles.scss';
import { Header } from "./ui/header";
import { WomanOfferIcon } from "./ui/womanOfferIcon";
import { Footer } from "./ui/footer";


export function Woman() {
  useEffect(() => {
    // Dodawanie klasy 'woman' do <body> przy montowaniu komponentu
    document.body.classList.add('woman');

    // Funkcja czyszcząca, która będzie wywoływana przy odmontowywaniu komponentu
    return () => {
      // Usuwanie klasy 'woman' z <body>
      document.body.classList.remove('woman');
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>Zmiana stylu ubierania – kobieta</title>
        <meta name="description" content={'Sprawdź ofertę i poznaj szczegóły zmiany stylu ubierania.'} />
      </Helmet>
      <Header />
      <article>
        <div className="flex">
          <div id="content">
          <h1>Zmiana stylu ubierania – klucz do pewności siebie</h1>
          <h2>Przekształć swoje życie dzięki zmianie stylu</h2>
          <p>Czy zastanawiałaś się kiedyś, jak wielką moc mogą mieć zmiany w Twoim sposobie ubierania? Styl to nie tylko ubrania, to sposób na wyrażenie siebie i budowanie swojego wizerunku. Współpraca z profesjonalną stylistką otwiera drzwi do świata, w którym pewność siebie i profesjonalizm idą w parze z modą.</p>

          <h2>Dlaczego warto zainwestować w profesjonalne doradztwo stylistyczne?</h2>
          <p>Pierwsze wrażenie jest niezwykle ważne zarówno w życiu prywatnym, jak i zawodowym. To, co masz na sobie, może wpływać na to, jak jesteś postrzegana i jak postrzegasz siebie. Adrianna Malewska pomoże Ci odkryć ubrania, które podkreślą Twoje atuty, ukryją mankamenty i harmonijnie współgrają z Twoją osobowością. Zmiana stylu może być rewolucyjnym krokiem ku lepszemu ja.</p>

          <h2>Co zyskasz, decydując się na współpracę?</h2>
          <p>Adrianna Malewska to więcej niż stylistka – jestem Twoją osobistą konsultantką, pomogę Ci odnaleźć w sobie to, co najlepsze. Dzięki czemu nauczysz się, jak:</p>
          <ul>
              <li>Wybierać ubrania, które idealnie pasują do Twojej sylwetki,</li>
              <li>dobierać kolory, które rozświetlają Twoją urodę i podkreślają indywidualność,</li>
              <li>kreować spójne i przemyślane stylizacje na każdą okazję,</li>
              <li>budować garderobę, która będzie funkcjonalna i czasowa.</li>
          </ul>
          <p>Zmiana stylu to inwestycja w siebie, która przynosi długoterminowe korzyści.</p>

          <h2>Indywidualne podejście – klucz do sukcesu w modzie</h2>
          <p>Każda kobieta jest wyjątkowa, a jej styl powinien odzwierciedlać jej osobowość, życiowy rytm i aspiracje. Jako profesjonalna stylistka skupiam się na indywidualnym podejściu do każdej klientki, co gwarantuje, że proponowane rozwiązania będą idealnie dopasowane do Twoich potrzeb i oczekiwań.</p>

          <h2>Zmień swój styl, zmień swoje życie</h2>
          <p>Decyzja o zmianie stylu to pierwszy krok do odkrycia swojego potencjału i zbudowania silnej, pozytywnej samooceny. Niezależnie od tego, czy potrzebujesz odświeżenia codziennego looku, czy przygotowujesz się do specjalnej okazji, Zapewnię Ci wsparcie i ekspertyzę, które pomogą Ci świecić najjaśniejszym blaskiem.</p>

          <h2>Zapraszam do kontaktu!</h2>
          <p>Jeśli jesteś gotowa na transformację, która odmieni nie tylko Twój wizerunek, ale i samopoczucie, skontaktuj się ze mną. <a href="mailto:kontakt@malewska.com">Napisz</a> lub <a href="tel:796200365">zadzwoń</a> do mnie. Odkryj swoją najlepszą wersję z profesjonalistką, która z pasją podchodzi do każdej stylizacji.</p>
          </div>
          <WomanOfferIcon />
        </div>
        <div id="ariclePhoto">
          <img src='/img/kobieta.jpg' alt="Adrianna Malewska" width={2160} height={1440} loading='lazy' />
        </div>
      </article>
      <Footer />
    </>
  )
}